.app-l-fileUpload-section {
  border: 1px dashed $grey8;
  box-sizing: border-box;
  border-radius: 6px;
  background: $app-card-grey;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  // // @include display-flex();
  // // @include align-items(center);
  // // @include justify-content(center);
  height: 120px;

  .app-l-fileUpload-holder {
    display: flex;
    align-items: center;
    // @include display-flex();
    // @include align-items(center);
    // width: 240px;
    white-space: nowrap;
    overflow: hidden;

    @media screen and (max-width: 480px) {
      flex-direction: column;
      width: 112px;

      label {
        margin-right: 0 !important;
      }
    }

    .app-c-drag-file {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 13px;
      color: $grey4;
      margin-right: 27px;
      width: auto;
      // @include display-flex();
      // @include align-items(center);
      // @include justify-content(space-between);
      // @include flex-wrap(nowrap);
      font-weight: 600;
      color: #8a97ba;

      img {
        margin-right: 16px;
      }
    }

    .app-c-file-input {
      input {
        height: 0;
        width: 0;
      }
    }
  }
}

.text-overflow-class {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.app-c-validation-message {
  font-size: 14px;
  color: red;
}

select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  position: relative;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: none !important;

  &:focus {
    background-image: linear-gradient(45deg, green 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, green 50%);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
    box-shadow: none !important;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
}

.app-c-sorting {
  min-height: 24px !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  min-width: 24px !important;
  color: $grey8 !important;
  position: absolute !important;
  right: -2px !important;
  top: 3px !important;
  margin: 0 !important;
  outline: none !important;

  &:hover {
    color: $primary-color-dark !important;
  }

  &.sorting,
  &.descending,
  &.ascending {
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      font-family: "Material Design Icons" !important;
      position: absolute;
      top: 50%;
      right: 7px;
      // font-size: 9px;
      font-size: 15px;
      font-weight: 600;
      display: inline-block;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  &.sorting {
    &::before {
      content: "\F140";
      margin-top: 1px;
      padding-top: 3px;
    }

    &::after {
      content: "\F143";
      margin-top: -8px;
    }
  }

  &.descending {
    &::before {
      opacity: 0;
    }

    &::after {
      content: "\F143";
      margin-top: -2px;
      // font-size: 10px;
      font-size: 20px;
      font-weight: 600;
      color: $primary-color-dark !important;
    }
  }

  &.ascending {
    &::before {
      content: "\F140";
      margin-top: -2px;
      // font-size: 10px;
      font-size: 20px;
      font-weight: 600;
      color: $primary-color-dark !important;
    }

    &::after {
      opacity: 0;
    }
  }
}

.app-c-search,
.app-c-members-search {

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button {
    display: none;
  }
}

.app-c-table-search-clear {
  position: absolute;
  right: 5px;
  top: 7px;
  background: transparent;
  border: none;
  font-size: 18px;
  outline: none;

  i {
    &::before {
      color: $primary-color-dark;
    }
  }
}

.app-l-filter-dropdown-list {
  width: 100%;
  .app-l-dropdown-scroll-holder {
    max-height: 230px;
    overflow: auto;
    margin: 10px 0 15px;
    float: left;
    width: 100%;
  }
}

//styles for customFileUpload.js
.app-c-file-upload-preview {
  background: radial-gradient(circle, rgba(91,98,107,0), rgba(91,98,107,1));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
  height: 150px;
  display: flex;
  position: relative;

  img,
  video {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  img {
    object-fit: contain;
  }

  video {
    object-fit: fill;
  }

  .app-c-file-input {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 1000;

    input {
      width: 0px;
      height: 0px;
    }
  }
}

.arrow-pointer {
  i {
    &::after {
      margin-right: 10px;
      font-size: large;
    }

    &::before {
      margin-right: 10px;
      font-size: large;
    }
  }
}

.parentRow {
  img {
    float: left;
  }

  .childRow {
    padding-left: 72px;
    padding-top: 8px;
  }
}

.table-badge {
  text-align: center;

  .badge {
    padding: 4px;
  }
}

.view-badge {
  .badge {
    padding: 4px;
  }
}

.change-data {
  background-color: #fff;
  border: 1px double #eee;
  color: rgb(41, 146, 208);
  padding: 20px 10px 25px 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 4px 4px 3px #999999;
}

.fetching {
  display: flex;
  justify-content: center;
  font-size: 30px;
  padding: 20px 0;
  font-weight: 200;
}

.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select-option-wrapper {
  display: flex;
  align-items: center;
}

.selected-option {
  margin-right: 10px;
}

.rounded-50 {
  border-radius: 50px;
}

.custom-label {
  display: flex;

  .label-sub-block {
    display: flex;
    flex-direction: column;
    margin: auto 10px;

    .main-text {
      font-weight: 500;

    }

    .sub-text {
      font-size: 12px;
    }
  }

}

.format-label__control {
  min-height: calc(1.5em + 0.75rem + 2px) !important;
  height: calc(1.5em + 0.75rem + 2px) !important;

  .format-label__value-container {
    .custom-label {
      .main-text {
        font-weight: 100;
      }

      img,
      .sub-text {
        display: none
      }
    }
  }
}

// access-profile

.access-top-row {
  border: 1px solid #e7e7e8;
  background: #f2f2f2;

  .col-2,.col-6 {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .row {
    width: 100%;

    .__checkbox {
      label {
        padding-left: 30px;
      }
    }
  }
  button {
    color: #fff;
    background-color: #ec4561;
    border-color: #ec4561;

    &:hover,&:focus,&:active {
      color: #fff;
      background-color: #c93b52;
      border-color: #bd374e;
    }
  }
}

.access-content {
  border: 1px solid #e7e7e8;
  border-top: 0;
  align-items: center;
  .__checkbox {
    label {
      padding-left: 30px;
    }
  }
  .col-12 {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: baseline;
  }

  .flex-column {
    padding-bottom: 0 !important;
  }

  .row {
    width: 100%;
  }

  .w-100 {
    .col {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .access-content-button-holder {
    .col {
      flex-direction: column;
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;

      button {
        margin-bottom: 10px;
        color: #fff;
        background-color: #ec4561;
        border-color: #ec4561;

        &:hover,&:focus,&:active {
          background-color: #c93b52;
          border-color: #bd374e;
        }
      }

      div {
       padding: 8px 0;
          button {
            margin-bottom: 0;
          }
        &+div{
          padding-top: 0!important;
        }
      }
    }
  }
}

.dots {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PhoneInput{
  border: 1px solid #ced4da;
    border-radius: 0.25rem;
    min-height: 35px;
    padding: 0.375rem 0.75rem;
    input{
      border: none!important;
      outline: none!important;
      box-shadow: none!important;
    }
}

.scroll-card{
  @media (max-width: 768px) {
    overflow-x: auto;
    .card-body{
      width: 1000px;
    }
  }

}