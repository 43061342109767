//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark {
  color: $gray-200 !important;
}

.btn-outline-dark {
  &:hover {
    color: $gray-200 !important;
  }
}

.app-c-link-btn {
  border: 0px !important;
  background: transparent;
  background-color: transparent !important;
  color: $blue !important;
  font-family: $font-family-sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 14.63px;
  white-space: nowrap;
  &:focus {
    outline: none;
    border: 0px !important;
  }
  &.inline {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    color: #a244a2;
    background: none;
  }
}
.app-c-link-btn2 {
  border: 0px !important;
  background: transparent;
  background-color: transparent !important;
  color: $blue !important;
  font-family: $font-family-sans-serif;
  font-size: 0.875rem;
  // font-weight: 700;
  line-height: 14.63px;
  // white-space: nowrap;
  &:focus {
    outline: none;
    border: 0px !important;
  }
  &.inline {
    display: inline-block;
    vertical-align: middle;
  }
  &:hover {
    color: #a244a2;
    background: none;
  }
}
