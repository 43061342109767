.list-pagination {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  padding-top: 20px;

  .lp-dropdown {
    width: 80px;
    align-self: baseline;
    padding-top: 5px;
    max-width: 80px;
    flex: 0 0 80px;
  }

  .dataTables-info-pad-2 {
    width: fit-content;
    padding-top: 7px;
  }

  .pg-link {
    width: fit-content;
    position: absolute;
    right: -12px;

    @media (max-width: 480px) {
      position: relative;
      margin: auto;
      right: 0;
    }
  }
}

.action {
  text-align: center;
}

.action-col {
  white-space: nowrap;
  text-align: center;

  // padding-left: 15px;
  // width: 100px;
  span {
    margin: 0px 5px;
  }
}

.password-show-hide{
position: relative;
cursor: pointer;
}
.show-hide{
  cursor: pointer;
  position: absolute;
  top:35px;
  left: 325px;

  @media (min-width: 280px){
    font-size: 12px;
    top:38px;
    left: 90%;
  }
  
  
}
.show-hide.set-password {
  cursor: pointer;
  position: absolute;
  top:10px;
  left: 325px;

  @media (min-width: 280px){
    font-size: 12px;
    left: 90%;
  }
}
.show-hide-change{
  cursor: pointer;
  position: absolute;
  top:35px;
  left: 450px;
  @media (max-width: 900px) {
    font-size: 12px;
    top:35px;
    left: 250px;
} 
}
.table-responsive > .table-bordered {
  border-bottom: #e9ecef 2px solid;
}

.file-delete {
  text-decoration: none;
  font-size: 25px;
  padding: 0;
  height: auto;
  min-height: auto;
  line-height: normal;
  background: transparent;
  border: none;
  outline: none !important;
  padding-right: 10px;
}

.app-c-file-input-btn {
  background-color: #ffffff;
  font-weight: 700;
  color: #6d8195;
  cursor: pointer;
  padding: 6px 22px;
  border: 1px solid #d8e0e5;
  border-radius: 50px;
  margin: 0;

  // overflow: hidden;
  &:hover {
    background-color: #e8e8ff;
    color: white;
  }
}

.attachment-lable {
  display: flex;
  flex-wrap: wrap;

  .app-l-text-support {
    margin-left: auto;
  }
}

sup {
  color: $red;
}

.input-icon {
  position: relative;

  i {
    position: absolute;
    display: block;
    transform: translate(0, -50%);
    top: 50%;
    pointer-events: none;
    width: 25px;
    text-align: center;
    font-style: normal;
    font-size: 15px;
  }

  input {
    padding-left: 25px;
    padding-right: 0;
  }
}

.description {
  max-width: 800px;
}

.sourceurl {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fade {
  opacity: 1;
}

.disabled {
  background-color: #ebecee !important;
}

a {
  cursor: pointer;
}

.btn-facebook {
  background: #3b5998;
  color: #fff;
}

.btn-facebook:hover {
  color: #fff;
  opacity: 0.8;
}

.btn-delete-account {
  width: 40px;
  text-align: center;
  box-sizing: content-box;
}

small {
  font-size: 95%;
}

.view-page {
  font-size: 16px;
  label {
    margin-bottom: 0;
  }
  small {
    margin-top: 0;
  }
}

.image-view {
  text-align: center;
}

.app-c-mw-social {
  @media (max-width: 480px) {
    flex-direction: column;
    button {
      min-height: 40px;
    }
    button + button {
      margin-top: 10px !important;
    }
  }
  .btn-social {
    width: 100%;
    border: 0;
    padding: 7px;

    .pre-img {
      margin-right: 8px;

      @media (max-width: 992px) {
        margin-right: 15px;
      }

      img {
        width: 16px;
      }
    }

    .title-info {
      white-space: nowrap;
    }

    &.google {
      background: white;
      border: 1px solid #626ed4;
      border-radius: 5px;

      &:hover {
        background: #f9f9fc;
        // box-shadow:0 0.75rem 1.5rem rgba(26,39,126,0.16);
      }
    }

    & + .btn-social {
      margin-left: 8px;
    }
  }
}

.rs-picker-menu.fade {
  opacity: 1;
  z-index: 2000;
}

.table-design {
  text-align: center;
  // width: 200px;
}
.truncate-text {
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 25px;
}

.enable-scroll {
  overflow: auto;
}

@mixin boxShadow($shadow...) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  -o-box-shadow: $shadow;
}

.autocomplete-dropdown-container:not(:empty) {
  top: auto;
  bottom: 100%;
  margin: 0px -12px 0 -16px;
  @include boxShadow(2px 3px 4px 3px #cdcdcd);
  background: $white;
  max-height: 400px;
  display: block;
  overflow: auto;

  .input-sugggestion {
    padding: 10px 6px 10px 30px;
    cursor: pointer;

    &:hover {
      background: #ad9df3;
    }

    & + .input-sugggestion {
      border-top: 1px solid #cdcdcd;
    }
  }
}

.creatable-dropdown {
  div {
    z-index: 10;
  }
}

.image-from-initials {
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  font-size: 24px;
  font-family: $font-primary;
  letter-spacing: 3px;
  color: $white;
  padding-top: 20px;
}

.default-image-container {
  background: $blue;
  border-radius: 5px;
  height: 48px;
  width: 48px;
  text-align: center;
}

.profile-preview {
  width: 98vw;
  height: 98vh;
  position: fixed;
  top: 1%;
  left: 1%;
}

.min-160 {
  min-height: 160px;
}

.min-auto {
  min-height: auto;
}

.change-password-text {
  color: #626ed4;
  margin-bottom: 10px;
}

.app-l-profile-publish-details {
  .row {
    .col-lg-12 {
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 440px) {
        margin: 0 0;
      }

      button,
      .badge {
        min-width: 120px;
        text-align: center;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        margin: 5px 5px 10px;

        @media (max-width: 440px) {
          width: 100%;
          margin: 5px 0 10px;
        }
      }

      .dropdown {
        @media (max-width: 440px) {
          width: 100%;
        }
      }
    }
  }
}

.table-user-content {
  min-width: 150px !important;
  width: 150px;
}

.table-email-content {
  min-width: 200px !important;
  width: 200px;
}

.vertical-collpsed {
  .app-l-page-layout {
    display: flex;

    .vertical-menu {
      position: relative;
    }

    .main-content {
      flex: 1;
      margin-left: 0;
    }
  }
}

.app-c-file-input {
  margin-top: 5px;
}

.app-l-obituary-profile-holder {
  .table-responsive {
    overflow-x: visible;
  }

  @media (max-width: 592px) {
    .app-l-table-holder {
      tr {
        display: flex;
        flex-direction: column;
        th {
          min-width: 100% !important;
          z-index: 0;
          position: relative;
          border-width: 1px !important ;
          border-bottom-width: 0 !important ;
          &:nth-child(1) {
            z-index: 3;
          }
          &:nth-child(2) {
            z-index: 2;
          }
          &:nth-child(3) {
            z-index: 1;
          }
        }
      }
    }
    [data-test='datatable-table'] {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .app-l-obituary-card {
      .col-xl-6 {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
    .card-view {
      flex-direction: column;
      margin-right: 0 !important;
      margin-left: 0 !important;
      .form-group {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      
    }
    
    .sort-dropDown {
      width: 100%;
    }
    .app-l-filter-holder {
      .col-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        .btn,
        .form-group {
          width: 100%;
        }
        
      }
    }
  }
}
@media (max-width: 592px) {
  .container-fluid {
    .page-title-box {
      padding-bottom: 12px !important;
    }
    .align-items-center {
      margin-bottom: 20px;
    }
  }
}

.manage-user {
  .form-control:disabled {
    background: #ebeef3 !important;
  }
  .access-group-list {
    label {
      padding-left: 30px;
    }
  }
}

.rounded-circles {
  background: #626ed4;
  color: white;
  width: 30px;
  flex: 0 0 30px;
  max-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.header-name {
  font-size: 14px;
  padding: 2px;
  font-weight: 500;
  margin-right: 2px;
}

.app-l-card-tags-holder {
  // @include display-flex();
  // @include flex-wrap(wrap);
  // @include align-items(center);
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .app-l-tag-item {
    // background: $grey15;
    // border: 1px solid $border-light;
    background: #f9f9f9 !important;
    border: 1px solid #d8e0e5;
    border-radius: 6px !important;
    margin: 4px 8px 4px 0;
    padding: 8px 12px 8px 8px;
    font-size: 13px;
    line-height: 12px;
    color: $grey4;
  }
}

.sortable-list-helper {
  z-index: 2000;
}
.customer-feedback-status {
  width: 100%;
  @media (max-width: 592px) {
    width: 140px;
  }
}
.customer-feedback-list {
  @media (max-width: 420px) {
    overflow-x: auto;
    .table {
      width: 600px;
    }
  }
}

.relative {
  position: relative;
}

.disabled-button {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}
