// Checkbox
.__checkbox {
    min-height: 20px;
    height:20px;
    position: relative;
    @media screen and (max-width: 592px) {
        margin-bottom: 10px;
    }
    label {
        position: relative;
        padding-left: 25px;
        margin: 0;
        min-height: 22px;
        line-height: 22px;
        &:before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 18px;
            height:18px;
            left: 0px;
            border: 1px solid $grey3;
            background: $checkbox-bg-color ;
            -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
            outline: 0 !important;
            // @include border-radius-all(2px);
        }
        &:after {
            display: inline-block;
            position: absolute;
            width: 18px;
            height: 18px;
            left: -1px;
            top: -3px;
            padding-top: 1px;
            font-size: 15px!important;
            color: $white;
            text-align: center;
        }
    }
    &.pl-c-35{
        label{
            padding-left: 35px
          } 
    }
    input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        &:focus+label:before,
        &:hover+label:before {
            // @include boxShadow(0 0 3px $checkbox-shadow);
        }
        &:checked+label::before {
            border: 1px solid $primary-color!important;
            background:$primary-color!important;
        }
        &:checked+label::after {
            font-family: "Material Design Icons" !important;

            content:"\FE6E";
        }
        &:disabled+label {
            opacity: 0.65;
            &:before {
                background: $gray-101;
                cursor: not-allowed;
            }
        }
    }
}

//Radio
.__radio {
    position: relative;
    @media screen and (max-width: 592px) {
        margin-bottom: 10px;
    }
    label {
        position: relative;
        padding-left: 20px;
        margin: 0;
        height: 22px;
        line-height: 22px;
        color: $grey4;
        cursor: pointer;
        &:before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 14px;
            height: 14px;
            left: 0;
            top: 3px;
            border: 1px solid $gray-900;
            opacity: 0.54;
            border-radius: 50%;
            background-color: $checkbox-bg-color;
            -webkit-transition: border 0.15s ease-in-out;
            -o-transition: border 0.15s ease-in-out;
            transition: border 0.15s ease-in-out;
            outline: 0 !important;
        }
        &:after {
            display: inline-block;
            position: absolute;
            content: " ";
            width: 6px;
            height: 6px;
            left: 4px;
            top: 7px;
            border-radius: 50%;
            // background-color: $orange4;
            -webkit-transform: scale(0, 0);
            -ms-transform: scale(0, 0);
            -o-transform: scale(0, 0);
            transform: scale(0, 0);
            -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
            transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
        }
    }
    input[type="radio"] {
        opacity: 0;
        position: absolute;
        &:focus+label::before,
        &:hover+label::before {
            // @include boxShadow(0 0 8px $checkbox-shadow);
        }
        &:checked+label::before {
            // border: 1px solid $orange4!important;
        }
        &:checked+label::after {
            -webkit-transform: scale(1, 1);
            -ms-transform: scale(1, 1);
            -o-transform: scale(1, 1);
            transform: scale(1, 1);
        }
        &:disabled+label {
            opacity: 0.65;
            &::before {
                cursor: not-allowed;
            }
        }
    }
    &.radio-inline {
        margin-top: 0;
    }
    &.auto-width{
        width: auto;
        display: inline-block;
        padding-right: 20px;
    }
}

// Radio Form Input
.form-input-radio {
    display: flex;
    justify-content: space-between;
    .radio__option {
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 0.5em;
        cursor: pointer;

        .radio__input {
            display: flex;
            input {
                opacity: 0;
                width: 0px;
                height: 0px;
                & + .radio__control::before {
                    content: "";
                    width: 0.5em;
                    height: 0.5em;
                    box-shadow: inset 0.5em 0.5em $radio-checked;
                    border-radius: 50%;
                    -webkit-transition: border 0.15s ease-in-out;
                    -o-transition: border 0.15s ease-in-out;
                    transition: border 0.15s ease-in-out;
                    -webkit-transform: scale(0, 0);
                    -ms-transform: scale(0, 0);
                    -o-transform: scale(0, 0);
                    transform: scale(0, 0);
                }
                &:checked + .radio__control::before {
                    -webkit-transform: scale(1, 1);
                    -ms-transform: scale(1, 1);
                    -o-transform: scale(1, 1);
                    transform: scale(1, 1);
                }
                &:checked + .radio__control {
                    background: radial-gradient($radio-checked 50%, $white 51%);
                    border-color: $radio-checked;
                    border-width: 0.15em;
                }
                &:disabled + .radio__control {
                    cursor:not-allowed;
                }
            }
            .radio__control {
                display: grid;
                place-items: center;
                width: 1em;
                height: 1em;
                border-radius: 50%;
                border: 0.1em solid $gray-500;
                transform: translateY(-0.05em);
            }
        }

        .radio__label {
            line-height: 1;
            font-weight: 400;
        }
    }
}