.gapi-view-selector-container {
  display: flex;
  @media (max-width: 800px) {
    display: inline;
  }
}
.analytics-auth-widgets .analytics-sign-out-button {
  margin: 20px 0 20px 0;
  width: 100px;
}
.gapi-data-chart-container {
  width: 100%;
}
.ga-chart {
  display: flex;
  @media (max-width: 850px) {
    display: inline;
  }
  @media (max-width: 1024px) {
    display: inline;
  }
}
.ga-inner {
  width: 47%;
  margin-right: 2%;
  margin-bottom: 2%;
  @media (max-width: 850px) {
    width: 90%;
    margin-bottom: 2%;
  }
  @media (max-width: 1024px) {
    width: 90%;
    margin-bottom: 2%;
  }
}
.google-visualization-table .google-visualization-table-table {
  height: 400px;
}
