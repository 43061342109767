//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th{
       white-space: nowrap;
  }
  td {
    word-break: break-word;
    // max-width: 200px;
    // width: 100%; 
    // white-space: nowrap;
  }
}

thead {
  tr {
    .multiselect-filter-checkbox {
      .rs-picker-has-value{
        .rs-picker-toggle-caret{
          &::before{
            color: $primary-color-bright
          }
        }
      }
      .rs-picker-toggle {
        position: absolute;
        width: auto;
        top: 0px;
        right: 0px;
        background-color: white !important;
        padding: 15px 0px 15px 0px;
        .active{
          background-color: white !important;
        }
        .rs-picker-toggle-value {
          display: none;
        }
        .rs-picker-toggle-clean {
          font-size: 12px;
          top: 12px;
          color: $loader-color;
          font-weight: 700;
          right: 25px;
        }
        .rs-picker-toggle-caret {
          font-size: 18px;
          color: #adafb0;
          right:5px !important;
          &::before {
            content: "\F236";
            font-family: "Material Design Icons" !important;
          }
          &::after {
            color: $grey4 !important;
          }
        }
        
        .rs-picker-check-menu-items{
          // max-height: 230px
        }
      }
    }
    th {
      // background: $grey2;
      border-bottom: 0;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      color: $grey4;
      padding-right: 40px;
      position: sticky;
      top: -1px;
      z-index: 1;
      .__checkbox {
        label {
          &:before {
            // border: 1px solid $grey1;
            // width: 18px;
            // height: 18px;
            // top: 5px;
          }

          &::after {
            // width: 18px;
            // height: 18px;
            // left: 0px;
            // top: 0px;
            // font-size: 6px !important;
          }
        }
      }

      .dropdown {
        margin-bottom: 15px;
        padding-bottom: 0 !important;

        &:last-child {
          margin-bottom: 7px !important;
        }

        .__checkbox {
          min-height: auto;

          label {
            text-transform: capitalize;
            // color: $grey4;
            font-size: 13px;
            line-height: 12px;
            min-height: auto;

            &:before {
              // border: 1px solid $grey3;
              width: 16px;
              height: 16px;
              top: -2px;
            }

            &::after {
              width: 16px;
              height: 16px;
              left: 0px;
              top: -1px;
              font-size: 14px !important;
            }
          }
        }
      }

      .app-c-filter-clear-icon {
        position: absolute;
        right: 25px;
        top: 12px;
        font-size: 12px;
        font-weight: 700;
        color: #f20;
        transition: 0.2s color linear;
        margin-bottom: 0px !important;
        background: transparent !important;
      }

      .app-c-filter-dropDown {
        position: absolute;
        right: 6px;
        top: 7px;
        margin-bottom: 0px !important;

        .appFilterButton {
          background: transparent !important;
          border: none !important;
          padding: 0;
          box-shadow: none !important;
          color: $grey8;
          font-size: 18px;
          min-width: auto !important;

          span {
            padding: 0 !important;
          }

          &:hover {
            color: $primary-color !important;
          }

          &:active {
            color: $grey4 !important;
          }

          &:focus {
            color: $grey4 !important;
          }

          &.active {
            position: relative;
            color: $primary-color-bright !important;

            &::before {
              content: "";
              position: absolute;
              width: 7px;
              height: 7px;
              // background: $red1;
              border: 1px solid $white;
              border-radius: 50%;
              top: -1px;
              right: -1px;
            }
          }

          &::after {
            display: none;
          }
        }

        .app-c-filter-clear-btn {
          background: $primary-color;
          border-color: $primary-color;
          color: $white;
          min-width: 60px;
          padding: 2px 10px;
          // @include justify-content(center);
          border-radius: 50px;
          box-shadow: 0px 4px 12px rgba(39, 27, 10, 0.21);
          float: right;
          min-height: auto;
          height: auto;
          font-size: 14px;

          &:hover {
            background: $primary-color-dark;
            border-color: $primary-color-dark;
            outline: none;
            box-shadow: 0px 4px 12px rgba(39, 27, 10, 0.21);
          }

          &:active {
            background: $primary-color-dark;
            border-color: $primary-color-dark;
            outline: none;
          }

          &:focus {
            // @include boxShadow(0px 0px 0px 2px rgba($app-brand-primary, 0.2));
            background: $primary-color-dark;
            border-color: $primary-color-dark;
          }
          &:disabled {
            color: $primary-color-dark;
          }
          &:not(:disabled):not(.disabled):active {
            // @include boxShadow(0px 0px 0px 2px rgba($app-brand-primary, 0.2));
            background: $primary-color-dark;
            border-color: $primary-color-dark;
          }
        }

        .dropdown-menu {
          right: 0 !important;
          left: auto !important;
          padding: 10px 8px 3px;
          transform: inherit !important;
          top: 30px !important;
          min-width: 200px;
          box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
          border: 1px solid #f3f4f6;
          max-height: 310px !important;
          @media (max-width: 592px) {
            right: 0 !important;
            left: auto !important;
          }
          .dropdown {
            padding-bottom: 7px;

            label {
              font-family: $font-primary;
              font-weight: normal;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }

      .app-c-search {
        position: absolute;
        // right: 45px;
        top: 1px;
        z-index: 1;
        width: calc(100% - 35px);

        input {
          outline: none;
        }

        input::-webkit-search-decoration,
        input::-webkit-search-cancel-button {
          display: none;
        }

        input::-ms-clear {
          display: none;
        }

        input[type="search"] {
          background: url(../../../images/searchIcon.svg) no-repeat 0 center;
          background-size: 14px;
          padding: 8px 10px 9px 10px;
          width: 14px;
          -webkit-border-radius: 10em;
          -moz-border-radius: 10em;
          border-radius: 10em;
          -webkit-transition: all 0.7s;
          -moz-transition: all 0.7s;
          transition: all 0.7s;
          color: $white;
          cursor: pointer;
          border: none;
          background-color: transparent;
          float: right;
          overflow: hidden;
        }

        &.app-c-search-expand {
          input {
            background: url(../../../images/searchIcon.svg) no-repeat 10px
              center;
            background-size: 14px;
            width: calc(100% - 0px);
            padding-left: 32px;
            padding-right: 30px;
            font-family: $font-primary;
            background-color: $white;
            cursor: auto;
            -webkit-box-shadow: $box-shadow-color;
            -moz-box-shadow: $box-shadow-color;
            box-shadow: $box-shadow-color;
            color: $black;
            margin-top: 2px;
          }
        }

        input:-moz-placeholder {
          color: transparent;
        }

        input::-webkit-input-placeholder {
          color: transparent;
        }
      }

      &.app-l-search-column {
        min-width: 150px !important;
        width: 220px  !important;
      }

      &.app-l-search-column-message-template {
        min-width: 210px !important;
        width: 220px  !important;
      }

      &.app-l-search-column-new-width {
        min-width: 150px !important;
        width: 300px  !important;
      }

      &.app-l-condensed-column {
        min-width: 115px !important;
        width: 115px !important;
      }

      &.app-l-width-condensed-column {
        min-width: 109px !important;
        width: 109px !important;
      }

      &.app-l-expanded-column {
        min-width: 250px !important;
        width: 130px !important;
      }

      &.app-l-135-column {
        min-width: 135px !important;
        width: 150px !important;
      }

      &.app-l-mini-condensed-column {
        min-width: 50px !important;
        width: 50px !important;
      }
      &.app-l-100-column{
      min-width: 100px!important;
      width: 120px!important;
      }
      &.remove-space-td {
        padding-right: 20px;
      }

      &.app-l-long-column {
        min-width: 190px  !important;
        width: 200px !important;
      }

      &.app-l-long-column-new-width {
        min-width: 190px  !important;
        width: 300px !important;
      }

      &.app-l-sub-long-column {
        min-width: 160px  !important;
        width: 200px !important;
      }
      &.app-l-ext-long-column {
        min-width: 300px !important;
        width: 300px !important;
      }

      &.app-l-action-column {
        width: 100px !important;
        padding-right: 15px !important;
      }
    }
  }
}

.w-80 {
  width: 80px !important;
  max-width: 80px !important;
}

.w-10 {
  width: 10px !important;
  max-width: 10px !important;
  min-width: 10px !important;
}

.remove-card-background{
  background-color: transparent !important;
  box-shadow: none !important;
}
.card-table-header{
  background-color: white;


}

.card-view {
  margin-bottom: 5px;
  .app-c-search {
    input{
      width: 100%;
    }
    button{
      position: absolute;
      right: 7px;
      top: 3px;
      background: transparent;
      border: none;
      font-size: 19px;
    }
  }
  .app-c-sorting{
    top: -6px !important;
    left: 160px;
    padding: 20px!important;
    background: white !important;
    border-radius: 5px;
    &::before{
      right: 13px !important;
    }
    &::after{
      right: 13px !important;
    }
  }

}
.width-250{
  width: 250px;
}
@media (max-width: 768px) {
  .app-l-obituary-profile-block{
     .app-l-table-holder{
      .table {
        min-width: 100%;
      }
     }
  }
  .table-responsive {
    .table {
      min-width: 800px;
    }
  }

  .table-responsive {
    .template-status-filter {
      min-width:  100% !important;
    }
  }


  
}
